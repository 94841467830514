/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.component.video .mejs-container {
  overflow: hidden;
}

.component.video .mejs-controls .mejs-button button {
  box-shadow: none;
}

.component.video .video-init {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin: -40px 0 0 -40px;
  background: url("/-/media/Base-Themes/Core-Libraries/styles/mejs-controls.svg") no-repeat;
  background-position: 0 -39px;
  overflow: hidden;
  z-index: 1;
}

.component.video .component-content {
  max-width: 1280px;
  margin: 0 auto;
}

.component.video .responsive-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.component.video .responsive-embed iframe, .component.video .embed-container object, .component.video .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.component.video .responsive-embed video {
  width: 100%;
}
